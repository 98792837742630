export class InvSnapshotFSLMockerRequest {
   public MessageType: string
   public Parameters: Parameters
  }
  
  export class Parameters {
    public Action: string
    public SenderPlant: string
    public MessageSender: string
    public Supplier: string
    public SupplierNodelocation: string
    public ReportingPlant: string
    public batchSize: number
    public InventoryAdviceLines: InventoryAdviceLine[]
  }
  
  export class InventoryAdviceLine {
    
  
  
    public ItemStatusType: string
    public ShipToPlant?: string
    public ShipFromPlant?: string
    public FromInventoryLocationId: string
    public Quantity: number
    public ItemSKU: string
    public VendorItemSKU: string
    public SerializedItemIndicator: boolean
    public ItemSerialNumbers: string[]
  }